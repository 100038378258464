<template>
  <div class="accordion my-6">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" role="tab">
        <b-button block size="sm" class="btn-collapse">
          <div class="w-100 d-flex justify-content-between align-items-center">
            <b-row>
              <b-col cols="2" class="d-flex align-items-center">
                <h6 class="text-success mb-0">Phần {{ sectionNumber }}</h6>
              </b-col>
              <b-col cols="5">
                <basic-input
                  maxlength="100"
                  placeholder="Nhập tên phần"
                  :name="`section-${id}`"
                  :state="validateState(`section-${id}`)"
                  :invalid-feedback="errors.first(`section-${id}`)"
                  v-validate="'required|max:100'"
                  data-vv-as="Tên phần"
                  v-model="section.name"
                  class="text-left section"
                />
              </b-col>
            </b-row>
            <div class="d-flex">
              <b-button
                size="sm"
                class="icon-trash mr-3"
                @click="deleteSection()"
              >
                <v-icon class="p-0" color="#FF5756"
                  >mdi-trash-can-outline</v-icon
                >
              </b-button>
              <b-button size="sm" class="icon-plus mr-3" @click="addQuestions">
                <v-icon class="p-0" color="#008479">mdi-plus</v-icon>
              </b-button>
              <b-button size="sm" class="icon-chevron" @click="open = !open">
                <v-icon class="p-0"
                  >mdi-{{ open ? 'chevron-up' : 'chevron-down' }}</v-icon
                >
              </b-button>
            </div>
          </div>
        </b-button>
      </b-card-header>
      <b-collapse
        :id="`accordion-${id}`"
        v-model="open"
        visible
        accordion="my-accordion"
        role="tabpanel"
      >
        <b-card-body>
          <basic-text-area
            label="Mô tả"
            placeholder="Nhập mô tả"
            maxlength="500"
            :name="`description-${id}`"
            :state="validateState(`description-${id}`)"
            :invalid-feedback="errors.first(`description-${id}`)"
            v-validate="'required|max:500'"
            data-vv-as="Mô tả"
            :value.sync="section.description"
          />
          <div v-if="questions.length">
            <template v-for="(question, index) in questions">
              <Question
                :id="question.id"
                :key="question.id"
                :question="question"
                :type="question.type"
                :showActionEdit="false"
                :questionNumber="index + 1"
                @delete="deleteQuestion"
              />
            </template>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import {
  ADD_QUESTION_SURVEY,
  DELETE_QUESTION_SECTION,
  DELETE_SECTION,
} from '@/core/services/store/survey.module';
import { MODAL_STATUS } from '@/core/plugins/constants';
import sortBy from 'lodash/sortBy';
const { mapMutations } = createNamespacedHelpers('survey');
export default {
  name: 'CollapseQuestion',
  components: {
    Question: () => import('./question.vue'),
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    sectionNumber: {
      type: Number,
      default: 1,
    },
    section: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      open: true,
      sectionName: null,
      sectionOrginal: null,
    };
  },
  watch: {
    'section.name': function (val) {
      if (val !== this.sectionOrginal.name && this.section.modelStatus !== 3) {
        this.section.modelStatus = MODAL_STATUS.CHANGED;
      }
    },
    'section.description': function (val) {
      if (
        val !== this.sectionOrginal.description &&
        this.section.modelStatus !== 3
      ) {
        this.section.modelStatus = MODAL_STATUS.CHANGED;
      }
    },
  },
  computed: {
    questions() {
      return sortBy(this.section?.questions, 'order') || [];
    },
  },
  mounted() {
    this.sectionOrginal = { ...this.section };
  },
  methods: {
    ...mapMutations({
      ADD_QUESTION_SURVEY,
      DELETE_QUESTION_SECTION,
      DELETE_SECTION,
    }),
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    deleteSection() {
      this.DELETE_SECTION({
        id: this.id,
        isEdit: this.$route.name === 'survey_edit',
      });
    },
    addQuestions() {
      this.$emit('add-question', this.id);
    },
    deleteQuestion(item) {
      const relatedQuestion = this.questions.find(
        (el) => el.mappedQuestionId === item.id,
      );
      if (relatedQuestion) {
        return this.showPopupWarning(relatedQuestion);
      }
      this.DELETE_QUESTION_SECTION({
        id: this.id,
        questionId: item.id,
        isEdit: this.$route.name === 'survey_edit',
      });
      return;
    },
    showPopupWarning(relatedQuestion) {
      this.$swal({
        title: 'Xác nhận',
        text: `Câu hỏi này là điều kiện hiển thị của câu hỏi ${relatedQuestion.name}`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(function () {}.bind(this));
    },
    handleValidate() {
      return new Promise((resolve) => {
        this.$validator.validateAll().then((result) => resolve(result));
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.text-success {
  color: #21a567 !important;
}

.icon-chevron {
  background-color: #def2f1 !important;
  border-color: #def2f1 !important;
}
.icon-plus {
  background-color: #def2f1 !important;
  border-color: #def2f1 !important;
}
.icon-trash {
  background-color: #ffebef !important;
  border-color: #ffebef !important;
}

.btn-collapse {
  background-color: #fff !important;
  border-color: #f3f6f9 !important;
  padding: 8px 24px;
}
</style>

<style lang="scss">
.section {
  .form-group {
    margin-bottom: 0;
  }
}
</style>

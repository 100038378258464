import { render, staticRenderFns } from "./CollapseQuestion.vue?vue&type=template&id=072b8902&scoped=true&"
import script from "./CollapseQuestion.vue?vue&type=script&lang=js&"
export * from "./CollapseQuestion.vue?vue&type=script&lang=js&"
import style0 from "./CollapseQuestion.vue?vue&type=style&index=0&id=072b8902&lang=scss&scoped=true&"
import style1 from "./CollapseQuestion.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "072b8902",
  null
  
)

export default component.exports